import fetch from "auth/GenericFetchInterceptor";

const PazientiService = {};

PazientiService.getPaziente = function (id) {
    return fetch({
        url: `/api/patients/${id}`,
        method: "GET",
    });
};

PazientiService.putPaziente = function (data, id) {
    return fetch({
        url: `/api/patients/${id}`,
        method: "PUT",
        data: data
    });
};

PazientiService.addPaziente = function (data) {
    return fetch({
        url: `/api/patients`,
        method: "POST",
        data: data,
    });
};
PazientiService.deletePatient = function (id) {
    return fetch({
        url: `/api/patients/${id}`,
        method: "DELETE",
    });
};

PazientiService.getPazienti = function (
    filtroOneYear,
    filtroUltima,
    filtroTipoProvenienza,
    filtroTipoPaziente,
    filtroNomeProvenienza,
    filtroStatoPaziente,
    puntiVendita
) {

    let idPuntiVendita="";
    puntiVendita.map((puntoVendita,index)=>{
        idPuntiVendita+=puntoVendita.id+","
    })
    idPuntiVendita = idPuntiVendita.substr(0, idPuntiVendita.length - 1);

    const filtroNomeProvenienzaFinal =
        filtroNomeProvenienza == ""
            ? ""
            : filtroNomeProvenienza.reduce((acc, elemento, index, arr) => {
                return acc + elemento;
            }, "");

    const filtroStatoPazienteFinal =
        filtroStatoPaziente == ""
            ? ""
            : filtroStatoPaziente.reduce((acc, elemento, index, arr) => {
                return acc + elemento;
            }, "");
    return fetch({
        url: `/api/estrazioni?puntiVendita=${idPuntiVendita}${filtroUltima}${filtroOneYear}${filtroTipoProvenienza}${filtroTipoPaziente}${filtroNomeProvenienzaFinal}${filtroStatoPazienteFinal}`,
        method: "GET",
    });
};

export default PazientiService;
