import {combineReducers} from 'redux'
import theme from './slices/themeSlice'
import auth from './slices/authSlice'
import pazienti from './slices/pazientiSlice'
import schedaPaziente from "./slices/schedaPazienteSlice";
import puntiVendita from "./slices/puntiVenditaSlice";
import provenienze from "./slices/ProvenienzeSlice"
import pazientiStatiTipi from "./slices/pazientiStatiTipiSlice"
import dashboardGenerale from "./slices/DashobardSlices";
import prodotti from "./slices/prodottiSlice"
//import utenti from "./slices/utentiSlice"
import events from "./slices/CalendarSlice"
import utentiAndRoles from "./slices/utentiAndRolesSlice";
const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        auth,
        pazienti,
        schedaPaziente,
        puntiVendita,
        provenienze,
        pazientiStatiTipi,
        dashboardGenerale,
        prodotti,
      //  utenti,
        events,
        utentiAndRoles,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}

export default rootReducer;