import React from 'react'
import {AUTH_PREFIX_PATH, APP_PREFIX_PATH} from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/register`,
        component: React.lazy(() => import('views/auth-views/authentication/register')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    }
]

export const protectedRoutes = [
    {
        key: 'dashboards-default',
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        component: React.lazy(() => import('views/app-views/dashboards')),
    },
    {
        key: 'agenda',
        path: `${APP_PREFIX_PATH}/agenda`,
        component: React.lazy(() => import('views/app-views/agenda')),
    },
    {
        key: 'scheda-paziente',
        path: `${APP_PREFIX_PATH}/pazienti-appuntamenti/pazienti/:id`,
        component: React.lazy(() => import('views/app-views/pazienti/schedaPaziente')),
    },
    {
        key: 'pazienti-lista',
        path: `${APP_PREFIX_PATH}/pazienti/lista`,
        component: React.lazy(() => import('views/app-views/pazienti/listaPazienti')),
    },
    {
        key: 'pazienti-aggiungi',
        path: `${APP_PREFIX_PATH}/pazienti/aggiungi`,
        component: React.lazy(() => import('views/app-views/pazienti/nuovoPaziente')),
    },
    {
        key: 'pazienti-stato-lista',
        path: `${APP_PREFIX_PATH}/pazienti/stato/lista`,
        component: React.lazy(() => import('views/app-views/pazienti/gestioneStatiPazienti/listaTypes')),
    },
    {
        key: 'pazienti-stato-aggiungi',
        path: `${APP_PREFIX_PATH}/pazienti/stato/aggiungi`,
        component: React.lazy(() => import('views/app-views/pazienti/gestioneStatiPazienti/nuovoTypes')),
    },
    {
        key: 'provenienze-lista',
        path: `${APP_PREFIX_PATH}/provenienze/lista`,
        component: React.lazy(() => import('views/app-views/provenienze/listaProvenienze')),
    },
    {
        key: 'provenienze-aggiungi',
        path: `${APP_PREFIX_PATH}/provenienze/aggiungi`,
        component: React.lazy(() => import('views/app-views/provenienze/nuovaProvenienza')),
    },
    {
        key: 'gestisci-utenti',
        path: `${APP_PREFIX_PATH}/ruoli/gestisci-utenti`,
        component: React.lazy(() => import('views/app-views/ruoli/gestisci-utenti')),
    },
    {
        key: 'gestisci-punti',
        path: `${APP_PREFIX_PATH}/ruoli/gestisci-punti`,
        component: React.lazy(() => import('views/app-views/ruoli/gestisci-punti')),
    },
    {
        key: 'estrazioni',
        path: `${APP_PREFIX_PATH}/estrazioni/pazienti`,
        component: React.lazy(() => import('views/app-views/estrazioni')),
    },
    {
        key: 'modifica-profilo',
        path: `${APP_PREFIX_PATH}/modifica-profilo`,
        component: React.lazy(() => import('views/app-views/profilo')),
    },
    {
        key: 'not-found',
        path: `${APP_PREFIX_PATH}/not-found`,
        component: React.lazy(() => import('views/app-views/page-not-found')),
    },
]
