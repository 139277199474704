import fetch from 'auth/GenericFetchInterceptor'

const ProdottiService = {}


ProdottiService.getProdotti = function () {
    return fetch({
        url: `/api/products?&pagination[limit]=10000&populate=*`,
        method: 'GET'
    })
}





export default ProdottiService;