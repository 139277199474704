import moment from "moment";
const loadingCofig = {
    onRenderTypeSingle:"month",
    onRenderTypeMultiple:"months",
    afterRenderType:"month",
    //numero di mesi/settimane da scaricare in fase di primo rendering
    onRender: 4,
    //numero di mesi/settimane da scaricare nelle fasi successive
    afterRender: 2,

};

const tipoCalendari = [
    { value: 'appuntamento', label: 'Appuntamenti' },
    { value: 'nota', label: 'Note' },
];

const StatiAppuntamenti = [
    { value: 'APPUNTAMENTO CONFERMATO', label: 'APPUNTAMENTO CONFERMATO' },
    { value: 'APPUNTAMENTO MANCATO', label: 'APPUNTAMENTO MANCATO' },
    { value: 'APPUNTAMENTO IN SOSPESO', label: 'APPUNTAMENTO IN SOSPESO' },
];



const getIndexStart = () => {
    return moment(moment(moment()).startOf('month')).subtract(0, 'months').startOf('month').subtract(1, "weeks");
};

const getIndexEnd = () => {
    return moment(moment()).add(0, 'months').endOf('month').add(1, "weeks");
};


export { loadingCofig,tipoCalendari,StatiAppuntamenti,getIndexStart,getIndexEnd };