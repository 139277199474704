import fetch from 'auth/FetchInterceptor'
import fetchLogin from 'auth/LoginInterceptor'
const AuthService = {}

AuthService.login = function (data) {

	return fetchLogin({
		url: '/api/auth/local?populate=*',
		method: 'post',
		data: data
	})
}

AuthService.getRole = function (data) {
	//SOSTITUITO PER OTTENERE ANCHE I NOMI
	/*return fetch({
		url: '/api/users/me?populate=*',
		method: 'get',
	})*/
	return fetch({
		url: '/api/users/me?pagination[limit]=-1&populate[0]=role&populate[1]=company&populate[2]=binding_roles.store',
		method: 'get',
	})
}

AuthService.getFotoProfilo = function (data) {
	//SOSTITUITO PER OTTENERE ANCHE I NOMI
	return fetch({
		url: '/api/users/me?pagination[limit]=-1&populate[binding_roles][populate][0]=store&populate=foto_profilo',
		method: 'get',
	})
}

AuthService.resetPassword = function (data) {

	return fetch({
		url: '/api/auth/reset-password',
		method: 'post',
		data: data
	})
}

AuthService.forgotPassword = function (data) {

	return fetch({
		url: '/api/auth/forgot-password',
		method: 'post',
		data: data
	})
}

AuthService.check = function () {

	return fetchLogin({
		url: '/api/users/count',
		method: 'get'
	})
}

AuthService.register = function (data) {
	return fetch({
		url: '/auth/register',
		method: 'post',
		data: data
	})
}

AuthService.logout = function () {
	return fetch({
		url: '/auth/logout',
		method: 'post'
	})
}

AuthService.loginInOAuth = function () {
	return fetch({
		url: '/auth/loginInOAuth',
		method: 'post'
	})
}

export default AuthService;