import fetch from 'auth/GenericFetchInterceptor'

const strapiService = {}

strapiService.get = function (url) {
  return fetch({
    url: url,
    method: 'get'
  })
}

strapiService.setPost = function (data) {
  return fetch({
    url: '/posts',
    method: 'post',
    data: data
  })
}

export default strapiService