import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {UTENTI_AND_ROLES} from 'configs/AppConfig'
import {errorMessage} from "../../utils/messages";
import RoleService from "../../services/RoleService";
import {USERS_VALUE} from "../../constants/AuthConstant";

export const initialState = UTENTI_AND_ROLES

export const loadUtentiAndRoles = createAsyncThunk('carica-utenti-and-roles', async (bindings_role, {rejectWithValue,getState}) => {

    try {
        console.log("loadUtentiAndRoles")

        const company=getState().auth.company
        //OTTENERE GLI UTENTI CHE SONO ABILITATI SUGLI STESSI PUNTI VENDITA (ATTRAVERSO I NOMI DEGLI STORE)
        let response = await RoleService.getUsers(company)
        const responseFoto = await RoleService.getUsersWithFoto(company)
        for (let i = 0; i < responseFoto.length; i++) {
            response[i].foto_profilo = responseFoto[i].foto_profilo;
        }

        const filteredResponse = response.filter(user => user.email !== "info@maffle.it");
        console.table(filteredResponse);
        return filteredResponse;

    } catch (err) {
        console.error("err", err)
        let message = (
            <div>
                Qualcosa è andato storto nel recuperare i pazienti. Riprovare!
                <br/>
                Se il problema persiste, contattare l' assistenza.
            </div>
        );

        errorMessage(message)
        return rejectWithValue('ERRORE')
    }
})


export const utentiAndRolesSlice = createSlice({
    name: 'utentiAndRoles',
    initialState,
    reducers: {
        updateUsers: (state, action) => {

        },
    }, extraReducers: (builder) => {
        builder
            .addCase(loadUtentiAndRoles.pending, (state) => {
                state.loadingUtentiAndRoles = true
                state.loadedUtentiAndRoles = false
                state.errorUtentiAndRoles = false

            })
            .addCase(loadUtentiAndRoles.fulfilled, (state, action) => {
                state.loadingUtentiAndRoles = false
                state.utentiAndRoles = action.payload
                localStorage.setItem(USERS_VALUE, JSON.stringify(action.payload))
                state.loadedUtentiAndRoles = true
                state.errorUtentiAndRoles = false
            })
            .addCase(loadUtentiAndRoles.rejected, (state, action) => {
                state.loadingUtentiAndRoles = false
                state.loadedUtentiAndRoles = true
                state.errorUtentiAndRoles = true
                state.utentiAndRoles = []
            })
    }
})


export default utentiAndRolesSlice.reducer;