import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {PAZIENTI} from 'configs/AppConfig'
import StrapiService from "../../services/StrapiService";
import moment from "moment";
import {errorMessage} from "../../utils/messages";
import {PAZIENTI_ERROR, PAZIENTI_LOADED, PAZIENTI_MAP_VALUE, PAZIENTI_VALUE} from "../../constants/AuthConstant";
import CalendarService from "../../services/CalendarService";
import PazientiService from "../../services/PazientiService";
import SchedaPazienteService from "../../services/SchedaPazienteService";

export const initialState = PAZIENTI


// Funzione per ottenere una lista unica di studi di riferimento per ID
function getUniqueStudiosById(data) {
    const groupedById = {};

    // Raggruppa gli elementi per ID
    data.forEach(entry => {
        if (!groupedById[entry.id]) {
            groupedById[entry.id] = {...entry};
            groupedById[entry.id].studio_di_riferimento = [];
        }
        groupedById[entry.id].studio_di_riferimento.push(entry.studio_di_riferimento);
    });

    // Crea un array con gli oggetti raggruppati per ID e studi di riferimento unici
    const result = Object.values(groupedById).map(entry => ({
        ...entry,
        studio_di_riferimento: [...new Set(entry.studio_di_riferimento)]
    }));

    return result;
}


export const loadPazienti = createAsyncThunk('carica-pazienti', async (data, {rejectWithValue, getState}) => {
    console.log("[loadPazienti]");

    const roles = getState().auth.binding_roles.map(role => role.id)

    try {
        let queryString = ""
        for (let i = 0; i < roles.length; i++) {
            queryString += "&filters[binding_roles][id]=" + roles[i]
        }
        //api/stores?&pagination[limit]=10000populate=*&filters[binding_roles][id]=5
        let pazienti = []
        let result = []
        if (queryString.length !== 0) {

            const response = await StrapiService.get('api/stores?&pagination[limit]=-1&populate=*' + queryString);

            for (let i = 0; i < response.data.length; i++) {
                for (let j = 0; j < response.data[i].attributes.patients.data.length; j++) {
                    let nomeStore = response.data[i].attributes.name;
                    let paziente = response.data[i].attributes.patients.data[j];
                    pazienti.push({
                        id: paziente.id,
                        nome: paziente.attributes.nome,
                        cognome: paziente.attributes.cognome,
                        data_di_nascita: paziente.attributes.data_di_nascita == null ? "N.D" : moment(paziente.attributes.data_di_nascita).format("DD-MM-YYYY"),
                        citta: paziente.attributes.citta == null ? "N.D" : paziente.attributes.citta,
                        citta_di_residenza: paziente.attributes.citta_di_residenza == null ? "N.D" : paziente.attributes.citta_di_residenza,
                        tipo_provenienza: paziente.attributes.tipo_provenienza,
                        nome_provenienza: paziente.attributes.nome_provenienza,
                        stato_paziente: paziente.attributes.stato_paziente == null ? "N.D" : paziente.attributes.stato_paziente,
                        tipo_paziente: paziente.attributes.tipo_paziente,
                        provenienza: paziente.attributes.tipo_provenienza + " " + paziente.attributes.nome_provenienza,
                        cellulare: paziente.attributes.cellulare,
                        studio_di_riferimento: nomeStore
                    })
                }
            }
            result = getUniqueStudiosById(pazienti);
        }

        localStorage.setItem(PAZIENTI_VALUE, JSON.stringify(result))
        // console.table(result)
        return result

    } catch (err) {
        console.error("err", err)
        let message = (
            <div>
                Qualcosa è andato storto nel recuperare i pazienti. Riprovare!
                <br/>
                Se il problema persiste, contattare l' assistenza.
            </div>
        );

        errorMessage(message)
        return rejectWithValue('ERRORE')
    }
})
export const deletePaziente = createAsyncThunk('cancella-paziente', async (id, {rejectWithValue, getState}) => {
    console.log("[deletePaziente]");
    try {
        let currentState = getState().pazienti.pazienti.map((item) => ({
            ...item,
        }))
        await PazientiService.deletePatient(id);
        const indexobj = currentState.findIndex((element) => element.id === id);
        currentState.splice(indexobj, 1)
        return currentState
    } catch (err) {
        console.error("err", err)
        let message = (
            <div>
                Qualcosa è andato storto nel cancellare il paziente. Riprovare!
                <br/>
                Se il problema persiste, contattare l' assistenza.
            </div>
        );

        errorMessage(message)
        return rejectWithValue('ERRORE')
    }
})

export const editPatient = createAsyncThunk('modifica-paziente', async (input, {rejectWithValue, getState}) => {
    try {
        console.log("[editPatient]",input.id)
        let currentState = getState().pazienti.pazienti.map((item) => ({
            ...item,
        }))
        const indexobj = currentState.findIndex((element) => element.id === parseInt(input.id));
        console.log("ale-debug currentState[indexobj]", currentState[indexobj])
        let updatedPatient = setPatient(input.updatedFields)
        currentState[indexobj] = updatedPatient
        localStorage.setItem(PAZIENTI_VALUE, JSON.stringify(currentState))
        return currentState
    } catch (err) {
        console.error("err", err)
        let message = (
            <div>
                Non è stato possibile modificare l'evento.
                <br/>
                Se il problema persiste, contattare l' assistenza.
            </div>
        );

        errorMessage(message)
        return rejectWithValue('ERRORE')
    }
});

export const addNewPatient = createAsyncThunk('aggiungi-paziente', async ({formData,id}, {rejectWithValue, getState}) => {
    try {


        const currentState = getState().pazienti.pazienti;
        console.log("ale-debug addNewPatient id",id)
        console.log("ale-debug addNewPatient formData",formData)
        console.log("ale-debug paziente da aggiungere",setAddPatient(formData,id))
        localStorage.setItem(PAZIENTI_VALUE, JSON.stringify([...currentState, ...[setAddPatient(formData,id)]]))
        return [...currentState, ...[setAddPatient(formData,id)]];
    } catch (err) {
        console.error("err", err)
        let message = (
            <div>
                Non è stato possibile aggiungere il paziente.
                <br/>
                Se il problema persiste, contattare l' assistenza.
            </div>
        );

        errorMessage(message)
        return rejectWithValue('ERRORE')
    }
});
const setAddPatient = (patient,id) => {
    return {
        id: id,
        nome: patient.nome,
        cognome: patient.cognome,
        data_di_nascita: patient.data_di_nascita == null ? "N.D" : moment(patient.data_di_nascita).format("DD-MM-YYYY"),
        citta:patient.citta == null ? "N.D" : patient.citta,
        citta_di_residenza: patient.citta_di_residenza == null ? "N.D" : patient.citta_di_residenza,
        tipo_provenienza: patient.tipo_provenienza,
        nome_provenienza: patient.nome_provenienza,
        stato_paziente: patient.stato_paziente == null ? "N.D" : patient.stato_paziente,
        tipo_paziente: patient.tipo_paziente,
        provenienza: patient.tipo_provenienza + " " + patient.nome_provenienza,
        cellulare: patient.cellulare,
        studio_di_riferimento: patient.studio_di_riferimento.split(",")
    }
}
const setPatient = (patient) => {
    return {
        id: patient.id,
        nome: patient.nome,
        cognome: patient.cognome,
        data_di_nascita: patient.data_di_nascita == null ? "N.D" : moment(patient.data_di_nascita).format("DD-MM-YYYY"),
        citta:patient.citta == null ? "N.D" : patient.citta,
        citta_di_residenza: patient.citta_di_residenza == null ? "N.D" : patient.citta_di_residenza,
        tipo_provenienza: patient.tipo_provenienza,
        nome_provenienza: patient.nome_provenienza,
        stato_paziente: patient.stato_paziente == null ? "N.D" : patient.stato_paziente,
        tipo_paziente: patient.tipo_paziente,
        provenienza: patient.tipo_provenienza + " " + patient.nome_provenienza,
        cellulare: patient.cellulare,
        studio_di_riferimento: patient.studio_di_riferimento.split(",")
    }
}
export const pazientiSlice = createSlice({
    name: 'pazienti', initialState, reducers: {
        pazientiLoaded: (state, action) => {
            //console.log("state", state)
            state.loading = false
            state.pazienti = action.payload;
        },
        showLoading: (state) => {
            state.loading = true
        },
        updatePaziente: (state, action) => {
            const {id, updatedFields} = action.payload;
            state.pazienti = state.pazienti.map((paziente) => {
                if (paziente.id == id) {
                    const campiDaAggiornare = {};
                    for (const campo in updatedFields) {
                        if (campo in paziente) {
                            // questo if è importante, serve per modificare solo le chiavi del json che fanno parte della listaPazienti del redux!
                            if (updatedFields[campo] != null) {
                                if (campo !== "data_di_nascita") {
                                    campiDaAggiornare[campo] = updatedFields[campo];
                                } else {
                                    campiDaAggiornare[campo] = moment(updatedFields[campo]).format("DD-MM-YYYY");
                                }
                            } else {
                                campiDaAggiornare[campo] = "N.D";
                            }
                        }
                    }

                    return {
                        ...paziente,
                        ...campiDaAggiornare, // Combina i campi esistenti con i campi aggiornati
                    };
                }
                return paziente;
            });
        },
    }, extraReducers: (builder) => {
        builder
            .addCase(loadPazienti.pending, (state) => {
                state.loadingPazienti = true
                state.loadedPazienti = false
                state.errorPazienti = false
            })
            .addCase(loadPazienti.fulfilled, (state, action) => {
                state.loadingPazienti = false
                state.pazienti = action.payload

                localStorage.setItem(PAZIENTI_LOADED, true)
                localStorage.setItem(PAZIENTI_ERROR, false)
                const pazientiMap = action.payload.reduce((acc, patient) => {
                    acc[patient.id] = patient;
                    return acc;
                }, {});
                state.pazientiMap=pazientiMap
                localStorage.setItem(PAZIENTI_MAP_VALUE, JSON.stringify(pazientiMap))

                state.loadedPazienti = true
                state.errorPazienti = false
            })
            .addCase(loadPazienti.rejected, (state, action) => {
                state.loadingPazienti = false
                state.pazienti = []
                state.pazientiMap={}

                localStorage.setItem(PAZIENTI_LOADED, true)
                localStorage.setItem(PAZIENTI_ERROR, true)
                localStorage.removeItem(PAZIENTI_VALUE)
                localStorage.removeItem(PAZIENTI_MAP_VALUE)
                state.loadedPazienti = true
                state.errorPazienti = false
            })
            .addCase(deletePaziente.pending, (state) => {
                state.loadingDeletePazienti = true
                state.loadedDeletePazienti = false
                state.errorDeletePazienti = false
            })
            .addCase(deletePaziente.fulfilled, (state, action) => {
                state.loadingDeletePazienti = false
                state.pazienti = action.payload
                localStorage.setItem(PAZIENTI_VALUE, JSON.stringify(action.payload))

                const pazientiMap = action.payload.reduce((acc, patient) => {
                    acc[patient.id] = patient;
                    return acc;
                }, {});
                state.pazientiMap=pazientiMap
                localStorage.setItem(PAZIENTI_MAP_VALUE, JSON.stringify(pazientiMap))

                state.loadedDeletePazienti = true
                state.errorDeletePazienti = false
            })
            .addCase(deletePaziente.rejected, (state, action) => {
                state.loadingDeletePazienti = false
                state.loadedDeletePazienti = true
                state.errorDeletePazienti = false
            })
            .addCase(editPatient.fulfilled, (state, action) => {
                state.pazienti = action.payload
                const pazientiMap = action.payload.reduce((acc, patient) => {
                    acc[patient.id] = patient;
                    return acc;
                }, {});
                state.pazientiMap=pazientiMap
                localStorage.setItem(PAZIENTI_MAP_VALUE, JSON.stringify(pazientiMap))
            })
            .addCase(addNewPatient.fulfilled, (state, action) => {
                state.pazienti = action.payload
                const pazientiMap = action.payload.reduce((acc, patient) => {
                    acc[patient.id] = patient;
                    return acc;
                }, {});
                state.pazientiMap=pazientiMap
                localStorage.setItem(PAZIENTI_MAP_VALUE, JSON.stringify(pazientiMap))
            })

    }
})

export const {
    updatePaziente,
} = pazientiSlice.actions

export default pazientiSlice.reducer;