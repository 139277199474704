import fetch from 'auth/GenericFetchInterceptor'

const SchedaPazienteService = {}


SchedaPazienteService.getSchedaPaziente = function (id) {
    return fetch({
        url: `/api/patients/${id}?populate=*`,
        method: 'GET'
    })
}

SchedaPazienteService.putSchedaPaziente = function (id, data) {
    return fetch({
        url: `/api/patients/${id}?populate=stores`,
        method: 'PUT',
        data: data
    })
}

SchedaPazienteService.postVisita = function (data) {
    return fetch({
        url: `/api/visits`,
        method: 'POST',
        data: data
    })
}

SchedaPazienteService.putVisita = function (id, data) {
    return fetch({
        url: `/api/visits/${id}`,
        method: 'PUT',
        data: data
    })
}

SchedaPazienteService.deleteVisita = function (id) {
    return fetch({
        url: `/api/visits/${id}`,
        method: 'DELETE'
    })
}

SchedaPazienteService.getCronologia = function (id) {
    return fetch({
        url: `/api/patients/${id}?fields=cronologia`,
        method: 'GET',
    })
}

SchedaPazienteService.aggiornaCronologia = function (id, data) {
    return fetch({
        url: `/api/patients/${id}`,
        method: 'PUT',
        data: data
    })
}

export default SchedaPazienteService;