import fetch from 'auth/GenericFetchInterceptor'

const PazientiStatiTipi = {}


/*PazientiStatiTipi.getStatiTipi = function () {
    return fetch({
        url: `/api/types-patients?&pagination[limit]=10000&populate=*`,
        method: 'GET'
    })
}*/
PazientiStatiTipi.getStatiTipi = function (queryString) {
    return fetch({
        //url: `/api/types-patients?&pagination[limit]=10000&populate=*`,
        url: `/api/types-patients?&pagination[limit]=-1&populate=sources&populate=stores`+queryString,
        method: 'GET'
    })
}
PazientiStatiTipi.inserisciStatiTipi = function (data) {
    return fetch({
        url: `/api/types-patients`,
        method: 'POST',
        data: data
    })
}

PazientiStatiTipi.deleteStatiTipi = function (id) {
    return fetch({
        url: `/api/sources/${id}`,
        method: 'DELETE',
    })
}


export default PazientiStatiTipi;