import axios from 'axios';
import {API_BASE_URL} from 'configs/AppConfig';
import {signOutSuccess} from 'store/slices/authSlice';
import store from '../store';
import {AUTH_TOKEN} from 'constants/AuthConstant';
import {notification} from 'antd';

const unauthorizedCode = [400, 401, 403, 404, 500, 508]

const service = axios.create({
    baseURL: API_BASE_URL,
    timeout: 6000
})

// Config
const TOKEN_PAYLOAD_KEY = 'authorization'

// API Request interceptor
service.interceptors.request.use(config => {

    const jwtToken = localStorage.getItem(AUTH_TOKEN) || null;

    if (jwtToken) {
        config.headers[TOKEN_PAYLOAD_KEY] = "Bearer " + jwtToken
    }

    return config
}, error => {
    // Do something with request error here
    notification.error({
        message: 'Autenticazione Fallita'
    })
    Promise.reject(error)
})

// API respone interceptor
service.interceptors.response.use((response) => {

    return response.data
}, (error) => {
    let notificationParam = {
        message: ''
    }
    console.log(unauthorizedCode)
    console.log(error)

    // PREPARAZIONE MESSAGGIO DI NOTIFICA IN ALTO A DESTRA
    if (unauthorizedCode.includes(error.response.status)) {

        if (error.response?.data?.error?.message == "Incorrect code provided") {
            notificationParam.message = 'Reset della password fallito'
            notificationParam.description = 'Utilizza il codice corretto inviato nella mail'

        } else if (error.response?.data?.error?.message == "Your account has been blocked by an administrator") {
            notificationParam.message = 'Account disabilitato!'
            notificationParam.description = "Conttattare l'amministratore del sistema"
        } else if (error.response?.data?.error?.message == "Invalid identifier or password") {
            notificationParam.message = 'Autenticazione fallita'
            notificationParam.description = "L'utente inserito non esiste o la password non è corretta!"
        } else {
            notificationParam.message = 'Autenticazione fallita'
            notificationParam.description = 'Per piacere rieffettua il login'
        }

        localStorage.removeItem(AUTH_TOKEN)
        store.dispatch(signOutSuccess())
    }
    notification.error(notificationParam)
    return Promise.reject(error);
});

export default service