import React  from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { AUTHENTICATED_ENTRY } from 'configs/AppConfig'

const PublicRoute = () => {

	const { token } = useSelector(state => state.auth)
	const expirationDate = token!=null ? new Date(JSON.parse(atob(token?.split('.')[1])).exp * 1000): null;
	const currentDate = new Date();
	return (token && currentDate<=expirationDate) ? <Navigate to={AUTHENTICATED_ENTRY} /> : <Outlet/>
}

export default PublicRoute