import {notification} from "antd";

export function successMessage(message) {
    let notificationParam = {
        message: 'Successo!'
    }
    notificationParam.description = message
    notification.success(notificationParam)
}

export function errorMessage(message) {
    let notificationParam = {
        message: 'Errore!'
    }
    notificationParam.description = message
    notification.error(notificationParam)
}

export function warningMessage(message) {
    let notificationParam = {
        message: 'Attenzione!'
    }
    notificationParam.description = message
    notification.warning(notificationParam)
}
